<template>
    <div class="me-notices">
        <Header>
            <i slot="left-one" class="me-icon-back" @click="$router.go(-1)" />
            <img slot="center" :src="headerIcon" style="height:25px;margin:0;" />
            
        </Header>
       <div class="me-notices__list" ref="view"
      v-scroll="{
      distance: 150,
      onTop: _onTop,
      onBottom: _onBottom
    }">
            <van-cell v-for="(item,key) in list" :key="key" >
                <div class="me-notices__vantitle" slot="title">
                  <Avatar width="45px" height="45px" v-if="item.isFriend || item.isGroup" :id="item.senderId" :src="item.senderPhoto" type="user" />
                    <div class="me-notices__vantitle--info" >                       
                        <span class="text" v-html="item.formartContent"></span>
                        <label v-html="item.creatTime"/>
                    </div>
                    <span v-if="item.isFriend" class="me-notices__vantitle--friendBtn">
                        <i :class="{'me-icon-success':true,'old':item.status == 1}" @click="item.status == -1 ? handlerBtns(item.tipId,1) : ''" v-if="item.status == -1 || item.status == 1" />
                        <i :class="{'me-icon-close':true, 'old':item.status == 0}" @click="item.status == -1 ? handlerBtns(item.tipId,0) : ''" v-if="item.status == 0 || item.status == -1" />
                    </span>
                    <span v-if="item.isGroup" class="me-notices__vantitle--friendBtn">
                        <i :class="{'me-icon-success':true,'old':item.status == 1}" @click="item.status == -1 ? handlerreplyGroupNotice(item.tipId,1,key) : ''" v-if="item.status == -1 || item.status == 1" />
                        <i :class="{'me-icon-close':true, 'old':item.status == 0}" @click="item.status == -1 ? handlerreplyGroupNotice(item.tipId,0,key) : ''" v-if="item.status == 0 || item.status == -1" />
                    </span>
                </div>
            </van-cell>
        </div>
    </div>
</template>
<script>
import {getNotices,replyNotice} from '@/api/imchat'
import {replyGroupNotice} from '@/api/im'
import { parseTime } from "@/utils";
import Header from "@/components/business/h5/header";
const quitImg = require(`assets/images/icon/group-quit.png`);
import Avatar from "@/components/common/avatar";
window._jumpPage = url => {
  window.location.href = url;
};
export default {
    name:'notices',
    components:{Header,Avatar},
    data(){
        return{
            id:'',
            language: '1',
            list:[],
            loading:false,
            finished:false,
            refreshing:false,
            pageSize:20,
            pageNum:1,
            lastQueryDate:'',
            type:'',
            headerIcon:''
        }
    },
   async created(){
       const user = JSON.parse(localStorage.getItem('userinfo') || {})
       this.id = user.id
        this.language = localStorage.getItem('langCode') || '1'
        this.type =  this.handlerSwitchType(this.$route.params.type)
        await this.$nextTick()
        this.list = await this.handlerGetMsglist()
        console.log(this.list,'/list')
    },
    methods:{
        parseTime,
       async handlerreplyGroupNotice(tipId,status,index){
           const user = this.$store.state.User.userinfo
           const result = await replyGroupNotice(tipId,status,user.token)
           if(result && result.code ==1){
               this.$toast.success()
               this.list[index].status = status

           }else{
               this.$toast.fail()
           }
           
        },
        async handlerBtns(tipid,status){
            const result = await replyNotice(tipid,status)
            console.log(result,'////ad')
            if(result && result.code == 1){
                this.pageNum = 1
                this.list = await this.handlerGetMsglist()
            }
        },
        deunicode(data){
            let str = "";
            if (!data) return "";
            data = data.split("\\u");

            if (data.length > 1) {
                for (var i = 0; i < data.length; i++) {
                str += String.fromCharCode(parseInt(data[i], 16).toString(10));
                }
            } else {
                return data;
            }
            return str;
            },
    getTipTypes(tipType, tipTypeId, title) {
        return {
            0: "",
            1:
            `<i class="me-icon-square" />` +
            (title
                ? `<a onclick="_jumpPage('#/user/${tipTypeId}/false/3')"> ${
                    title ? title : ""
                } </a>`
                : ""),
            2:
            `<i class="me-icon-square" />` +
            (title
                ? `<a onclick="_jumpPage('#/detail/${tipTypeId}')"> ${title} </a>`
                : ""),
            3: "",
            //转账转出
            4: "",
            //转账转入
            5: "",
            6: "",
            7: "",
            8: `<i class="me-icon-success"  style="color:#52bd68" />`,
            9:
            `<i class="me-icon-square" />` +
            (title
                ? `<a onclick="_jumpPage('#/group/${tipTypeId}')"> ${title} </a>`
                : ""),
            10:
            `<i class="me-icon-square" />` +
            (title
                ? `<a onclick="_jumpPage('#/detail/${tipTypeId}/enterprise')"> ${title} </a>`
                : ""),
            11: "申请提现",
            12: ``,
            21: `<i class='me-icon-zanSolid'>${title ? title : ""}</i>`,
            22:
            `<i class="me-icon-zanSolid" />` +
            (title
                ? `<a onclick="_jumpPage('#/detail/${tipTypeId}')" > ${title} </a>`
                : ""),
            23: "",
            29:
            '<i class="me-icon-zanSolid" />' +
            (title
                ? `<a onclick="_jumpPage('#/group/${tipTypeId}')"> ${title} </a>`
                : ""),
            30:
            `<i class="me-icon-zanSolid" />` +
            (title
                ? `<a onclick="_jumpPage('#/detail/${tipTypeId}/enterprise')"> ${title} </a>`
                : ""),
            41: "",
            42: "<i class='me-icon-circle-close' />",
            43: ` <img src="${quitImg}" style="height:18px;" />${title ? title : ""}`,
            44: ` <img src="${quitImg}" style="height:18px;" />${title ? title : ""}`,
            51: "<i class='me-icon-circle-close' />"
        }[tipType];
        },
        handlerSwitchType(type){
            console.log(type,'/', typeof type)
            let newtype = ''
            switch(type){
                case '7':
                    newtype = 'friend'
                    this.headerIcon = require('../../assets/images/notice/44.png')
                    break
                case '8':
                    newtype = 'system'
                    this.headerIcon = require('../../assets/images/notice/33.png')
                    break;
                case '9':
                    newtype= 'groups'
                    this.headerIcon = require('../../assets/images/notice/22.png')
                    break
                case '10':
                    newtype = 'wallet'
                    this.headerIcon = require('../../assets/images/notice/11.png')
                    break
            }
            return newtype
        },
        formartMsg(){
             //10005     群主删除人
            //10008     申请加入会员群
            //10009     被拒绝入群
            //20000      申请加好友消息
            //20001     添加好友申请，反馈结果，同意或不同意
            //20002      评论消息
            //20003      点赞消息
            //30000      钱包消息
            // this.list.map(item=>{
               
            // })
        },
        _onTop(){},
        async _onBottom(){
            this.pageNum++
            this.lastQueryDate = this.list[this.list.length - 1].msgTime;  
            const list = await this.handlerGetMsglist(this.lastQueryDate);            
            this.list = this.list.concat(list);
        },
        async handlerGetMsglist(){
            const params = {               
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                language:this.language,
            }
            let list = []
            const result = await getNotices(params,this.id,this.type)
            this.loading = false;
            this.refreshing = false

            if(result && result.data){
               list = result.data.data || []
            }
            switch(this.type){
                case 'system':
                    list = list.map(item=>{
                        return { ...item,formartContent:item.senderName + this.getTipTypes(item.tipType,item.typeId,this.deunicode(item.title))}
                    })
                    break;
                case 'groups':
                    list = list.map(item=>{
                        return {
                            creatTime:item.sendTime,
                            ...item,formartContent:item.senderName + ' ' + item.groupName,
                            isGroup:true
                        }
                    })
                    break
                case 'friend':
                    list = list.map(item=>{
                        return { ...item,creatTime:item.sendTime,isFriend:true, formartContent:item.senderName + (item.content? '<br/>' + item.content : ''  )}
                    })
                    break;
                case 'wallet':
                    list = list.map(item=>{
                        return {
                            ...item,
                            formartContent:`
                            <img style="width:18px;" src="${require('../../assets/images/notice/wall-'+item.tipType+'.png')}" />
                             <span style="color:#33cc66">${item.content}</span> ${item.tipType == 5 ? '+': '-'} ${item.tipContent}
                            `
                        }
                    })
                    break

            }
            return list
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.pageNum = 0

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.handlerGetMsglist();
        }
    }
}
</script>
<style lang="styl">
@import '~styles/utils/index';
+b(me-notices){    
    padding-top:54px;
    +e(list){
        height 100vh;
        overflow-y: scroll;
        .van-cell{
            +e(title){
                max-width:100%;
                overflow: hidden;
                /* white-space: nowrap; */
                text-overflow: ellipsis;
            }
        }
    }
    +e(vantitle){
        display flex;
        flex-flow row;
       
        +m(info){
            display flex;
            flex 1
            flex-flow column
            /* max-width 65%         */
            /* justify-content:space-between; */
            .text{
                max-width:100%;
                text-overflow: ellipsis;
                overflow:hidden;
                flex:1
            }
            label{
                font-size 12px
                color #999999;
            }

            img{
                margin:0 .25rem
            }
            a{
                color:#107cf6;
            }
            i{
                margin:0 .25rem
                &::before{
                    font-size:18px;
                }
                &.me-icon-zanSolid{
                    &::before{
                        color #f93f3f
                    }
                }
                &.me-icon-success{
                    opacity:.5
                    
                }
            }
        }
         +m(friendBtn){
             float:right;
             text-align right
             /* min-width 80px */
            i{
                &:first-child{
                   margin:0 .5rem;
                }
                &::before{
                    font-size:24px;
                }
                &.me-icon-success{
                    color:#33cc66;
                    opacity:1
                    
                }
                &.old{
                     opacity:.3
                }
            }
        }
    }
}
</style>