var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-notices" },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          },
          slot: "left-one"
        }),
        _c("img", {
          staticStyle: { height: "25px", margin: "0" },
          attrs: { slot: "center", src: _vm.headerIcon },
          slot: "center"
        })
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: {
                distance: 150,
                onTop: _vm._onTop,
                onBottom: _vm._onBottom
              },
              expression:
                "{\n  distance: 150,\n  onTop: _onTop,\n  onBottom: _onBottom\n}"
            }
          ],
          ref: "view",
          staticClass: "me-notices__list"
        },
        _vm._l(_vm.list, function(item, key) {
          return _c("van-cell", { key: key }, [
            _c(
              "div",
              {
                staticClass: "me-notices__vantitle",
                attrs: { slot: "title" },
                slot: "title"
              },
              [
                item.isFriend || item.isGroup
                  ? _c("Avatar", {
                      attrs: {
                        width: "45px",
                        height: "45px",
                        id: item.senderId,
                        src: item.senderPhoto,
                        type: "user"
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "me-notices__vantitle--info" }, [
                  _c("span", {
                    staticClass: "text",
                    domProps: { innerHTML: _vm._s(item.formartContent) }
                  }),
                  _c("label", {
                    domProps: { innerHTML: _vm._s(item.creatTime) }
                  })
                ]),
                item.isFriend
                  ? _c(
                      "span",
                      { staticClass: "me-notices__vantitle--friendBtn" },
                      [
                        item.status == -1 || item.status == 1
                          ? _c("i", {
                              class: {
                                "me-icon-success": true,
                                old: item.status == 1
                              },
                              on: {
                                click: function($event) {
                                  item.status == -1
                                    ? _vm.handlerBtns(item.tipId, 1)
                                    : ""
                                }
                              }
                            })
                          : _vm._e(),
                        item.status == 0 || item.status == -1
                          ? _c("i", {
                              class: {
                                "me-icon-close": true,
                                old: item.status == 0
                              },
                              on: {
                                click: function($event) {
                                  item.status == -1
                                    ? _vm.handlerBtns(item.tipId, 0)
                                    : ""
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                item.isGroup
                  ? _c(
                      "span",
                      { staticClass: "me-notices__vantitle--friendBtn" },
                      [
                        item.status == -1 || item.status == 1
                          ? _c("i", {
                              class: {
                                "me-icon-success": true,
                                old: item.status == 1
                              },
                              on: {
                                click: function($event) {
                                  item.status == -1
                                    ? _vm.handlerreplyGroupNotice(
                                        item.tipId,
                                        1,
                                        key
                                      )
                                    : ""
                                }
                              }
                            })
                          : _vm._e(),
                        item.status == 0 || item.status == -1
                          ? _c("i", {
                              class: {
                                "me-icon-close": true,
                                old: item.status == 0
                              },
                              on: {
                                click: function($event) {
                                  item.status == -1
                                    ? _vm.handlerreplyGroupNotice(
                                        item.tipId,
                                        0,
                                        key
                                      )
                                    : ""
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }